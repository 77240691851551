<template>
  <div class="h-100vh">
    <div class="logo-image-container text-center complain-header mb-3 py-3">
      <img src="@/assets/logo-d-dot.svg" class="logo-img" />
    </div>
    <div class="d-flex justify-content-center w-100 login-container">
      <div class="w-100 my-auto">
        <div class="d-flex justify-content-center">
          <div class="card p-5 mx-3 session-card">
            <div>
              <div class="text-center">
                <img
                  src="@/assets/images/icon-check.svg"
                  alt="success"
                  class="mb-3 img"
                />
              </div>
            </div>
            <div class="text-center f-size-22 font-weight-bold">
              {{ $t("messageDoneSuccess") }}
            </div>
            <div
              class="my-3 text-center"
              v-if="this.action == 'login' || this.action == 'register'"
            >
              <div>
                {{ $t("messageLoginSuccess") }}
              </div>
              <div class="pointer">
                {{ $t("messageReturnToChat") }}
                <u class="main-color" @click="redirectToLineOfficial"
                  >Kerry Express | Line Official Account</u
                >
              </div>
            </div>
            <div class="my-3 text-center" v-if="this.action == 'complain'">
              {{ $t("messageComplainSuccess") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      action: ""
    };
  },
  created: async function () {
    this.action = this.$route.params.action;
  },
  methods: {
    redirectToLineOfficial() {
      window.location = this.$lineOfficialAccount;
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100px;
}

.session-card {
  border-radius: 10px;
}
.login-container {
  height: calc(100vh - 160px);
}
</style>